"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FilterGroup = void 0;
class FilterGroup {
  constructor(_filters) {
    this._filters = _filters;
  }
  get filters() {
    return this._filters;
  }
  static create(...filters) {
    const filterGroup = new FilterGroup(filters);
    if (filterGroup.isEmpty()) {
      throw new Error("Empty filters are not allowed");
    }
    return filterGroup;
  }
  static EMPTY() {
    return new FilterGroup([]);
  }
  isEmpty() {
    return this._filters.length === 0;
  }
  /**
   * Check if is equal to provided group comparing its filters
   * @param group
   */
  equals(group) {
    if (this._filters.length !== group._filters.length) {
      return false;
    }
    for (let i = 0; i < this._filters.length; i++) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const groupFilter = group._filters[i];
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const filter = this._filters[i];
      if (!groupFilter.equals(filter)) {
        return false;
      }
    }
    return true;
  }
  toPlainObject() {
    return this._filters.map(filter => filter.toPlainObject());
  }
}
exports.FilterGroup = FilterGroup;