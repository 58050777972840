"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Filters = void 0;
class Filters {
  constructor(_groups) {
    this._groups = _groups;
  }
  get groups() {
    return this._groups;
  }
  /**
   * @throws Error if groups is empty
   * @param groups
   */
  static create(...groups) {
    const filters = new Filters(groups);
    if (filters.isEmpty()) {
      throw new Error("Empty groups not allowed");
    }
    return filters;
  }
  static EMPTY() {
    return new Filters([]);
  }
  isEmpty() {
    return this.groups.length === 0;
  }
  /**
   * Check if is equal to provided group comparing its filters
   * @param filters
   */
  equals(filters) {
    if (this.groups.length !== filters.groups.length) {
      return false;
    }
    for (let i = 0; i < this.groups.length; i++) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const filterGroup = filters.groups[i];
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const group = this.groups[i];
      if (!filterGroup.equals(group)) {
        return false;
      }
    }
    return true;
  }
  toPlainObject() {
    return this.groups.map(group => group.toPlainObject());
  }
}
exports.Filters = Filters;