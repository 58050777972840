"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Limit = void 0;
class Limit {
  get value() {
    return this._value;
  }
  constructor(_value) {
    this._value = _value;
    if (this.value < 0) {
      throw new Error("Invalid limit it must be at least 0");
    }
  }
  static NONE() {
    return new Limit(0);
  }
  equals(skip) {
    return this.value === skip.value;
  }
  isNone() {
    return this.equals(Limit.NONE());
  }
}
exports.Limit = Limit;