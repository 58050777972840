"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Operand = void 0;
class Operand {
  // biome-ignore lint/suspicious/noExplicitAny: Operand must accept any kind of value
  constructor(_value) {
    this._value = _value;
  }
  get value() {
    return this._value;
  }
  /**
   * Check if provided operand is equal comparing values
   * @param operand
   */
  equals(operand) {
    return this.value === operand.value;
  }
}
exports.Operand = Operand;