"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Order = void 0;
const Field_1 = require("../Filters/Filter/Field");
const OrderDirection_1 = require("./OrderDirection");
class Order {
  constructor(_field, _direction) {
    this._field = _field;
    this._direction = _direction;
  }
  get field() {
    return this._field;
  }
  get direction() {
    return this._direction;
  }
  static none() {
    return new Order(new Field_1.Field("."), OrderDirection_1.OrderDirection.NONE);
  }
  static asc(field) {
    return new Order(field, OrderDirection_1.OrderDirection.ASC);
  }
  static desc(field) {
    return new Order(field, OrderDirection_1.OrderDirection.DESC);
  }
  isNone() {
    return this.direction.equals(OrderDirection_1.OrderDirection.NONE);
  }
  equals(order) {
    return this.field.equals(order.field) && this.direction.equals(order.direction);
  }
  toPlainObject() {
    return {
      field: this.field.value,
      direction: this.direction.value
    };
  }
}
exports.Order = Order;