"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Field = void 0;
class Field {
  constructor(_value) {
    this._value = _value;
    if (!this.value) {
      throw new Error("Filter field is empty, null or undefined");
    }
  }
  get value() {
    return this._value;
  }
  /**
   * Check if provided field is equal comparing value
   * @param field
   */
  equals(field) {
    return this.value === field.value;
  }
}
exports.Field = Field;