"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Filter = void 0;
const Field_1 = require("./Field");
const Operand_1 = require("./Operand");
const Operator_1 = require("./Operator");
class Filter {
  constructor(_filterField, _filterOperator, _filterValue) {
    this._filterField = _filterField;
    this._filterOperator = _filterOperator;
    this._filterValue = _filterValue;
  }
  static create(field, operator,
  // biome-ignore lint/suspicious/noExplicitAny: Operand must accept any kind of value
  value) {
    return new Filter(new Field_1.Field(field), Operator_1.Operator.from(operator), new Operand_1.Operand(value));
  }
  get field() {
    return this._filterField;
  }
  get operator() {
    return this._filterOperator;
  }
  get operand() {
    return this._filterValue;
  }
  /**
   * Returns plain object representation of filter
   */
  toPlainObject() {
    return {
      field: this.field.value,
      operator: this.operator.value,
      operand: this.operand.value
    };
  }
  /**
   * Check if provided filter is equal comparing its values
   * @param filter
   */
  equals(filter) {
    return this.field.equals(filter.field) && this.operator.equals(filter.operator) && this.operand.equals(filter.operand);
  }
}
exports.Filter = Filter;