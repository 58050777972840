"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrderDirection = void 0;
class OrderDirection {
  get value() {
    return this._value;
  }
  constructor(_value) {
    this._value = _value;
  }
  static get entries() {
    return [this.NONE, this.ASC, this.DESC];
  }
  static from(value) {
    for (const orderDirection of this.entries) {
      if (orderDirection.value === value) {
        return orderDirection;
      }
    }
    throw new Error(`'${value}' is not a valid order direction`);
  }
  equals(direction) {
    return this.value === direction.value;
  }
}
exports.OrderDirection = OrderDirection;
OrderDirection.NONE = new OrderDirection("NONE");
OrderDirection.ASC = new OrderDirection("ASC");
OrderDirection.DESC = new OrderDirection("DESC");