"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Criteria = void 0;
const Filters_1 = require("./Filters/Filters");
const Limit_1 = require("./Limit");
const Orders_1 = require("./Orders/Orders");
const Skip_1 = require("./Skip");
class Criteria {
  get filters() {
    return this._filters;
  }
  get orders() {
    return this._orders;
  }
  get limit() {
    return this._limit;
  }
  get skip() {
    return this._skip;
  }
  constructor(params) {
    this._filters = params.filters;
    this._orders = params.orders;
    this._limit = params.limit || Limit_1.Limit.NONE();
    this._skip = params.skip || Skip_1.Skip.NONE();
  }
  static NONE() {
    return new Criteria({
      filters: Filters_1.Filters.EMPTY(),
      orders: Orders_1.Orders.EMPTY()
    });
  }
  toPlainObject() {
    return {
      filters: this.filters.toPlainObject(),
      orders: this.orders.toPlainObject(),
      limit: this.limit.value,
      skip: this.skip.value
    };
  }
  equals(criteria) {
    return criteria.filters.equals(this.filters) && criteria.orders.equals(this.orders) && criteria.limit.equals(this.limit) && criteria.skip.equals(this.skip);
  }
}
exports.Criteria = Criteria;