"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Orders = void 0;
class Orders {
  constructor(_orders) {
    this._orders = _orders;
  }
  get orders() {
    return this._orders;
  }
  static create(_orders) {
    const orders = new Orders(_orders);
    if (orders.isEmpty()) {
      throw new Error("Empty orders not allowed");
    }
    return orders;
  }
  static EMPTY() {
    return new Orders([]);
  }
  isEmpty() {
    return this.orders.length === 0;
  }
  /**
   * Check if is equal to provided group comparing its filters
   * @param orders
   */
  equals(orders) {
    if (this.orders.length !== orders.orders.length) {
      return false;
    }
    for (let i = 0; i < this.orders.length; i++) {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const order = orders.orders[i];
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      const _order = this.orders[i];
      if (!order.equals(_order)) {
        return false;
      }
    }
    return true;
  }
  toPlainObject() {
    return this.orders.map(order => order.toPlainObject());
  }
}
exports.Orders = Orders;